<template>
  <v-container class="mt-4 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">

          <!-- Header de las actas -->
          <v-card-title primary-title>
            <span class="text-subtitle-1">Actas administrativas</span>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="dialog = true"
              small
              rounded
            >
              Agregar
            </v-btn>
          </v-card-title>

          <!-- CONTENIDO -->
          <v-card-text>

            <!-- BUSCADOR -->
            <v-row justify="end">
              <v-col cols="12" md="6" lg="4">
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- TABLA -->
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="actas"
                  class="elevation-0"
                  :search="search"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                  :mobile-breakpoint="100"
                  hide-default-footer
                  dense
                >

                  <template v-slot:item.estatus="{ item }">
                    <v-chip small color="grey"  dark v-if="!item.estatus">Pendiente</v-chip>
                    <v-chip small color="green" dark v-if="item.estatus == 1">Aceptada</v-chip>
                    <v-chip small color="black" dark v-if="item.estatus == 2">Rechazada</v-chip>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-btn 
                      color="primary" 
                      x-small
                      @click="editItem(item)"
                      class="mr-2"
                    >
                      <v-icon small>mdi-magnify</v-icon>
                    </v-btn>

                    <v-btn 
                      color="error" 
                      x-small
                      @click="deleteItem(item)"
                      class="mr-2"
                    >
                      <v-icon small> mdi-delete</v-icon>
                    </v-btn>
                  </template>

                  <template v-slot:no-data>
                    <v-btn
                      color="primary"
                      @click="initialize"
                      small
                    >
                      Actualizar
                    </v-btn>
                  </template>

                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-pagination
              circle
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialogo de editar o agregar-->
    <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
            <strong>ID: {{ editedItem.idactas_admin }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <v-autocomplete
            filled
            dense
            clearable
            v-model="editedItem.id_usuario"
            :items="usuarios"
            label="Selecciona un usuario"
            item-text="nombre_completo"
            item-value="id_usuario"
          >
          </v-autocomplete>

          <v-autocomplete
            filled
            dense
            clearable
            v-model="editedItem.id_faltas"
            :items="faltas"
            label="Selecciona una falta"
            item-text="falta"
            item-value="idfaltas"
          >
          </v-autocomplete>

          <v-autocomplete
            filled
            dense
            clearable
            v-model="editedItem.id_sancion"
            :items="sanciones"
            label="Selecciona una sanción"
            item-text="sancion"
            item-value="idsancion"
          >
          </v-autocomplete>

          <v-textarea
            v-model="editedItem.motivo"
            filled
            rows="3"
            label="Agrega una descripción"
          >
          </v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="red"
            rounded
            dark
            small
            @click="close"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            rounded
            color="primary"
            class="elevation-6"
            dark
            small
            @click="save"
            v-if="editedItem.id_usuario"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row justify="space-around">
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table;">
                <div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
                  <strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el ciclo?
                  <v-card-actions class="pt-6">
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
                    <!-- Guardar la información  -->
                    <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="5">
              <v-img src="@/assets/borrar.png"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';
  import validarErrores        from '@/mixins/validarErrores'


  export default {
    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      
      editedIndex: -1,

      editedItem: {
        idactas_admin:0,
        id_asigno:0,
        id_usuario:0,
        motivo:'',
        id_sancion:0,
        id_faltas:0,
      },

      defaultItem: {
        idactas_admin:0,
        id_asigno:0,
        id_usuario:0,
        motivo:'',
        id_sancion:0,
        id_faltas:0,
      },

      // Filtros: 
      incio:null,
      fin:null,

      // tabla
      search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      actas: [],
      headers: [
        { text: 'ID'               , value: 'idactas_admin'  },
        { text: 'Empleado'         , value: 'asignado'       },
        { text: 'Falta'            , value: 'falta'          },
        { text: 'Sanción'          , value: 'sancion'        },
        { text: 'Motivo'           , value: 'motivo'         },
        { text: 'Estatus'          , value: 'estatus'        },
        { text: 'fecha creación'   , value: 'fecha_creacion' },
        { text: 'Actions'          , value: 'actions', sortable: false },
      ],

      faltas:[],
      sanciones:[],
      usuarios:[]
    }),

    computed: {
      ...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar actas admin.' : 'Editar actas admin.'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

    },

    async created () {
      await this.initialize()      
      await this.getFaltas()
      await this.getSanciones()
      await this.getUsuarios()

    },

    methods: {
      initialize () {
        this.cargar = true
        this.actas = []
        return this.$http.get('actas.list').then(response=>{
          this.actas = response.data
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getFaltas () {
        this.cargar = true
        this.faltas = []
        return this.$http.get('faltas.admin.list').then(response=>{
          this.faltas = response.data
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getSanciones () {
        this.cargar = true
        this.sanciones = []
        return this.$http.get('sanciones.admin.list').then(response=>{
          this.sanciones = response.data
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getUsuarios () {
        this.cargar = true
        this.usuarios = []
        return this.$http.get('usuarios.all.erp').then(response=>{
          this.usuarios = response.data

          for(const i in this.usuarios){
            this.usuarios[i].nombre_completo = this.usuarios[i].nombre_completo.toUpperCase() 
          }

          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.actas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.actas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.cargar = true
        // Lo mandapos por el EP
        this.editedItem['deleted'] = 1
        this.$http.put('actas.update/' + this.editedItem.idactas_admin, this.editedItem).then(response=>{
          this.cargar = true
          this.closeDelete()
          this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        // Cargamos al usuario que hizo el cambio
        this.cargar = true
        if (this.editedIndex > -1) {
          // Lo mandapos por el EP
          this.$http.put('actas.update/' + this.editedItem.idactas_admin, this.editedItem).then(response=>{
            this.validarSuccess( response.data.message )
            this.initialize()
            this.close()
          }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

        } else {
          this.editedItem.id_asigno = this.getdatosUsuario.iderp
          // Lo mandapos por el EP
          this.$http.post('actas.add', this.editedItem).then(response=>{
            this.initialize()
            this.cargar = false
            this.close()
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        }
      },
    },
  }
</script>


